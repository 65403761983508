import PropTypes from "prop-types"
import React, {useState,useEffect } from "react"
import Menu from "./Menu"

const Header = ({ siteTitle }) => {
  const pageUrl = typeof window !== 'undefined' ? window.location.pathname : '';
  const [url, setUrl] = useState(pageUrl)
  const [showFilter,setShowFilter] = useState(false)

  const updatePosts=(e)=>{
  // insightItems.pop();

  if (url.indexOf("/intermediaries") > -1) {
      setShowFilter(true)
      if(url==="/intermediaries/specialist-car-finance-intermediaries/"){
        setShowFilter(false)
      }
    }
  }
  useEffect(() => {
    updatePosts()
  }, []);

  return (
    <>
    {showFilter ?
      <div id="intermediaries-tab">For intermediaries only</div>
    :
    null}
  <header className="content">
  <Menu />
  </header>
  </>
)
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
