import React, {useState,useEffect } from "react"
import {Link, StaticQuery, graphql } from "gatsby"

import MenuItem from "./MenuItem"

/**
 * Define MenuItem fragment and get all primary menu items.
 */
const MOBILE_MENU_QUERY = graphql`

    fragment MenuItem on WPGraphQL_MenuItem {
        id
        label
        url
        title
        target
    }

    query GETMOBILEMENU {
        wpgraphql {
            menuItems(where: {location: MOBILE_MENU}) {
                nodes {
                    ...MenuItem
                    childItems {
                      nodes {
                        ...MenuItem
                        childItems {
                          nodes {
                            ...MenuItem
                            childItems {
                              nodes {
                                ...MenuItem
                                childItems {
                                  nodes {
                                    ...MenuItem
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                }
            }
            generalSettings {
                url
            }
        }
    }
`

const createLocalLink = url => {
  if (`#` === url) {
    return null
  }
  return url.replace(process.env.WORDPRESS_URL, ``)
}

const RenderMenuItem = menuItem => {

  const extension=menuItem.url.split('.').pop();

  if (menuItem.childItems && menuItem.childItems.nodes.length) {

    return RenderSubMenu(menuItem)

  } else {

        const link = createLocalLink(menuItem.url)
        return (
          <li
            key={menuItem.id}
            className="tier-0"
          >
            <span>
              <Link to={link}>{menuItem.label}</Link>
            </span>
          </li>
        )
    }
  }

  // Create a function to modify the classname (tier 2)
  function createPageClassTwo(pageLabel) {
    if (pageLabel == 'Broker Portal' ||
        pageLabel == 'Apply for Account' ||
        pageLabel.toLowerCase() == 'puma for intermediaries login'
    ) {
      return 'tier-2' + ' ' + 'blue';
    } else {
      return 'tier-2';
    }
  }

  function createPageClassThree(pageLabel) {
    if (pageLabel.toLowerCase() == 'puma for intermediaries login') {
      return 'tier-3' + ' ' + 'blue';
    } else {
      return 'tier-3';
    }
  }

  const RenderSubMenu = menuItem => {
    const webpageUrl = typeof window !== 'undefined' ? window.location.pathname : '';
    const [path, setPath] = useState(webpageUrl)
    const link = createLocalLink(menuItem.url)
    const classLabel=menuItem.id
    const classId=""
    const isCurrent=""
    const urlList=[]
    const urlSubList=[]
    const [mobAboutClicked, setAboutMobClicked] = useState(false);

    function openAboutMobileSub() {
      setAboutMobClicked(!mobAboutClicked);
    }

    return (
      <li
        key={menuItem.label}
        //  className="tier-0"
        className={link === '/personal/' || link === '/business/' || link === '/intermediaries/' ? 'tier-0 link-highlight' : 'tier-0'}
      >
        <span className={`${mobAboutClicked ? 'menu-title-tier-0 is-open' : 'menu-title-tier-0'}`}>
          <Link to={link}>{menuItem.label}</Link>
          <div className={`${mobAboutClicked ? 'white-mango-status-icon is-open' : 'white-mango-status-icon'}`} onClick={() => openAboutMobileSub()}></div>
        </span>
          <ul className="sub-menu" style={{display: mobAboutClicked ? 'block' : 'none'}}>
            <div className="sub-menu-wrapper">
              { menuItem.childItems.nodes.map(item => RenderSubMenuTierOne(item))}
            </div>
          </ul>
      </li>
    )
  }

  const RenderSubMenuTierOne = (item) => {
    const link = createLocalLink(item.url)
    return (
      <li
        key={item.id}
        className="tier-1"
      >
        <Link to={link} className={`${link !== null ? 'link-active' : 'link-null'}`}>{item.label}</Link>
        <ul className="sub-menu">
          { item.childItems.nodes.map(item => RenderSubMenuTierTwo(item))}
        </ul>
      </li>
    )
  }


  const RenderSubMenuTierTwo = (item) => {
    const link = createLocalLink(item.url)
    const pageClass = createPageClassTwo(item.label)
    return (
      <li
        key={item.id}
        className={pageClass}
      >
        <Link to={link} className={`${link !== null ? 'link-active' : 'link-null'}`}>{item.label}</Link>
        <ul className="sub-menu">
          { item.childItems.nodes.map(item => RenderSubMenuTierThree(item))}
        </ul>
      </li>
    )
  }

  const RenderSubMenuTierThree = (item) => {
    const link = createLocalLink(item.url)
    const pageClass = createPageClassThree(item.label)
    return (
      <li
        key={item.id}
        className={pageClass}
      >
        <Link to={link}>{item.label}</Link>
        <ul className="sub-menu">
          { item.childItems.nodes.map(item => RenderSubMenuTierThree(item))}
        </ul>
      </li>
    )
  }

  const MobileMenu = () => {

    const pageUrl = typeof window !== 'undefined' ? window.location.pathname : '';
    const [url, setUrl] = useState(pageUrl)
    const [showFilter,setShowFilter] = useState(false)
    const [showFeefoBadge,setFeefoBadge] = useState(false)

    const updatePosts=(e)=>{
    // insightItems.pop();
    if(url==="/personal-savings/"){
        setFeefoBadge(true)
      }
    }


    useEffect(() => {
      updatePosts()
    }, []);

  return (
    <StaticQuery
      query={MOBILE_MENU_QUERY}
      render={(data) => {
        if (data.wpgraphql.menuItems) {
          const menuItems = data.wpgraphql.menuItems.nodes
          const wordPressUrl = data.wpgraphql.generalSettings.url

          return (
          <>
            {data.wpgraphql.menuItems.nodes.map(menuItem => {
              if (menuItem.childItems.nodes.length) {
                return RenderSubMenu(menuItem)
              } else {
                return RenderMenuItem(menuItem)
              }
            })}
          </>

          )
        }
        return null
      }}
    />
  )
}

export default MobileMenu
